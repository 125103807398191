/**
 * PURPOSE: Provide reducers that are required for the initial boostrap of the application.  These reducers cannot
 *   be selectively loading based on feature toggle flags.  For the the other reducers that can be conditionally
 *   added take a look at `remainingReducers.ts`.
 */

// libraries
import { navigationReducer } from '@makemydeal/dr-activities-common';
import { locHrefUtil, apiConfigHelper, STUDIO_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { IConfigStudio } from '@makemydeal/dr-activities-studio';

// reducers
import { configReducer, sharedBootstrapReducer, featureToggleReducer } from '@makemydeal/dr-shared-store';

export const initialState: IConfigStudio = {
    services: {
        bff: {
            host: '',
            protocol: ''
        }
    }
};

const handleBootstrapInitialLoad: configReducer.BuildConfigReducerBootstrapInitialLoadCallback<IConfigStudio> = (
    appPrefix,
    action,
    state
) => {
    // get urls based on windows location
    const bffApiConfig = apiConfigHelper.buildBffApiConfig(appPrefix, locHrefUtil.getLocHref());
    const shallowCloned = { ...state };
    shallowCloned.services = {
        ...state.services,
        bff: {
            ...state.services.bff,
            ...bffApiConfig.configObj
        }
    };

    return shallowCloned;
};

export const initialReducers = {
    sharedBootstrap: sharedBootstrapReducer.reducer,
    config: configReducer.buildConfigReducer(STUDIO_APP_PREFIX, initialState, handleBootstrapInitialLoad),
    featureToggles: featureToggleReducer.reducer,
    navigation: navigationReducer
};
