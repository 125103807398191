// externals
import { useSelector } from 'react-redux';
import { StyleSheetManager as ScStyleSheetManager, ThemeProvider } from 'styled-components';

// libraries
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { HostEnvironment, hostUrlHelper, locHrefUtil, STUDIO_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { Alert } from '@interstate/components/Alert';
import { Action } from '@interstate/components/Action/Action/Action';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';

// store
import { sharedBootstrapSelectors } from '@makemydeal/dr-shared-store';

// style
import { BootstrapErrorContainer } from './BootstrapError.style';

const DEALER_SUPPORT_REQUEST_FORM = 'https://www.dealer.com/support/requestform/';

const DEFAULT_ERROR_MESSAGE = 'Unable to start the application.';

const StyleSheetManager = ScStyleSheetManager as any;

type BootstrapErrorProps = {
    loadingError?: string;
};

export const BootstrapError: React.FC<BootstrapErrorProps> = ({ loadingError }) => {
    const theme = useInterstateTheme();
    const bootstrapMeta = useSelector(sharedBootstrapSelectors.getBootstrapMeta);
    const environment = hostUrlHelper.getHostUrlEnvironment(STUDIO_APP_PREFIX, locHrefUtil.getLocHref());
    const errorMessage = bootstrapMeta?.responseErrorMessage || loadingError;

    const openSupportTicketUrl = () => {
        window.open(DEALER_SUPPORT_REQUEST_FORM);
    };

    if (!errorMessage && environment === HostEnvironment.Local) {
        return (
            <>
                An error occurred when bootstrapping the app - you will see this when running locally if (1) the BFF hasn't yet
                started up, (2) it isn't running properly, or (3) you have invalid query params. Try refreshing the browser if the
                first scenario applies.
            </>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <StyleSheetManager disableCSSOMInjection>
                <>
                    <BootstrapErrorContainer>
                        <>
                            <Alert type="error" sx={{ marginBottom: theme.tokens.BaseSpace60 }} data-testid="studio-error-alert">
                                <Typography variant="h6">Failed to Initialize</Typography>
                                <Typography variant="body-sm">
                                    Details: <span>{errorMessage || DEFAULT_ERROR_MESSAGE}</span>
                                </Typography>
                            </Alert>

                            <Grid gap={theme.tokens.BaseSpace10} alignItems="baseline" display="flex">
                                <Typography variant="strong-md" tag="span">
                                    Please contact support:
                                </Typography>
                                <Typography variant="body-md" tag="span">
                                    1-888-895-2994 or create a{' '}
                                </Typography>
                                <Action onClick={openSupportTicketUrl} data-testid="support-ticket">
                                    <Typography variant="anchor-inline-md" tag="a" color="sem.color.surface.button.primary.rest">
                                        support ticket
                                    </Typography>
                                </Action>
                            </Grid>
                        </>
                    </BootstrapErrorContainer>
                </>
            </StyleSheetManager>
        </ThemeProvider>
    );
};
