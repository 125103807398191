/**
 * PURPOSE: Contains logic related to the application bootstrap process.
 */

// libraries
import { buildBootstrapMiddleware, ReduxMiddleware, GetRemainingReducers } from '@makemydeal/dr-shared-store';

// interfaces/types
import { StudioStateTree } from '../types/stateTreeTypes';

// utils
import { getRemainingReducers } from '../reducers/remainingReducers';

// constants
import { APP_NAME } from '../../../constants';

export const internalBuildBootstrapMiddleware = (getRemainingReducers: GetRemainingReducers) => {
    return buildBootstrapMiddleware<StudioStateTree>({
        getGlobalRemainingReducers: getRemainingReducers,
        getDocumentTitle: () => APP_NAME
    });
};

export const bootstrapMiddleware: ReduxMiddleware<StudioStateTree> = internalBuildBootstrapMiddleware(getRemainingReducers);
