import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const BootstrapErrorContainer = styled.div`
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};
    padding-top: 5rem;
    padding-bottom: 6rem;
    min-height: 100vh;
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${BreakPoint.SMALL}) {
        margin-left: ${({ theme }) => theme.tokens.ComponentModalDialogBodyPadding};
        margin-right: ${({ theme }) => theme.tokens.ComponentModalDialogBodyPadding};
    }
`;
