// externals
import { Redirect } from 'react-router';
import { StyleSheetManager as ScStyleSheetManager, ThemeProvider } from 'styled-components';

// libraries
import type { Dealer } from '@makemydeal/dr-shared-types';
import type { FeatureTogglesAndUrlOnly } from '@makemydeal/dr-shared-store';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// config
import { studioConfiguration } from '../activities/studio-dashboard';
import { studioAllRoutes } from '../activities/studio-dashboard/config';

// components
import { BodyContainer } from './BodyContainer';

export type RoutesProps = {
    toggles: FeatureTogglesAndUrlOnly;
    dealer: Partial<Dealer>;
};

const StyleSheetManager = ScStyleSheetManager as any;

export const Routes: React.FC<RoutesProps> = () => {
    const theme = useInterstateTheme();

    const getLandingPage = () => {
        return studioAllRoutes.DASHBOARD;
    };

    return (
        <ThemeProvider theme={theme}>
            <StyleSheetManager disableCSSOMInjection>
                <>
                    <Redirect to={getLandingPage()} />
                    <BodyContainer>
                        <studioConfiguration.routingComponent />
                    </BodyContainer>
                </>
            </StyleSheetManager>
        </ThemeProvider>
    );
};
