import { Store } from 'redux';

import { BaseMiddleware } from '@makemydeal/dr-activities-common';

import { IConfigStudio, IHostActions } from '../types';

import { Selectors } from './selectors';

export class MiddlewareManager extends BaseMiddleware {
    constructor(
        public selectors: Selectors,
        public hostActions: IHostActions,
        public config: IConfigStudio,
        public baseRoute?: string,
        public analyticsAction?: (store: any, key: string, desc: string) => void
    ) {
        super(selectors, analyticsAction);
    }

    protected middlewareFunction = (store: Store<any>, _next: any, action: any): void => {
        switch (action.type) {
            default: {
                break;
            }
        }
    };
}
