// externals

// utils

// store

// types
import { IRootProps } from '../types';

// components

const Activity: React.FC<IRootProps> = () => {
    return (
        <div data-testid="dr-activities-studio">
            <h1>Hello world</h1>
        </div>
    );
};

export default Activity;
