// externals
import { useSelector } from 'react-redux';
import type { RouteComponentProps } from 'react-router';

// libraries
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

// routing
import { Routes } from './routes';

export const Routing: React.FC<RouteComponentProps> = (props) => {
    const toggles = useSelector(featureToggleSelectors.getFeatureToggles);
    const dealer = useSelector(dealerSelectors.getDealerState);

    return <Routes toggles={toggles} dealer={dealer} {...props} />;
};
