import * as React from 'react';
import { PropsRoute } from '@makemydeal/dr-activities-common';

import { IRootProps } from '../types';
import { getAllRoutes } from '../utils/routes';

import Activity from './Activity';

const Root: React.FC<IRootProps> = (props: IRootProps) => {
    const path = props.baseRoute;
    const routes = getAllRoutes(path);

    return (
        <div data-testid="dr-activities-studio-container">
            <PropsRoute {...props} path={routes.ROOT} component={Activity} />
        </div>
    );
};

export default Root;
