import { reducer } from '@makemydeal/dr-activities-studio';
import { APP_NAME, STUDIO_ROOT_ROUTE } from './constants';
import { StudioContainer } from './components/studioContainer';
import { studioMiddleware } from './middleware';
import { studioRoutes } from './config';

export const studioConfiguration = {
    name: APP_NAME,
    defaultRoute: STUDIO_ROOT_ROUTE,
    routes: studioRoutes,
    routingComponent: StudioContainer,
    middleware: studioMiddleware,
    reducer
};
