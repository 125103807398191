import { IConfigStudio, Selectors, getAllRoutes, init } from '@makemydeal/dr-activities-studio';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { APP_NAME, STUDIO_ROOT_ROUTE } from './constants';

export const studioAllRoutes = getAllRoutes(STUDIO_ROOT_ROUTE);
export const studioRoutes = Object.values(studioAllRoutes);

let studioSelectors: Selectors;

const additionalSelectors = {};
const hostActions = {};

export const configureStudio = (config: IConfigStudio) => {
    const { selectors, middlewareManager } = init(APP_NAME, additionalSelectors, hostActions, config, STUDIO_ROOT_ROUTE);
    studioSelectors = selectors;
    return middlewareManager;
};

export const getStudioSelectors = (): Selectors => studioSelectors;

export const getStudioConfigFromState = (state: any): IConfigStudio => {
    const featureToggles = featureToggleSelectors.getSimpleFeatureToggles(state);
    // TODO: fill with necessary config data
    const studioConfig = {
        services: {},
        featureToggles
    } as IConfigStudio;

    return studioConfig;
};
