export const APP_NAME = 'Studio';
// TODO: create an application scope if necessary
export const APP_SCOPE = '';

// TODO: replace to proper client ids once Bridge configuration for studio is done
export const CLIENT_ID = '0oa1c9hoa0wmTYUa2358';
export const NON_PROD_CLIENT_ID = '0oa1c9hktg67K6UcJ358';

export const OKTA_CONFIG_ID_PROD = 'cmd_prod_bridge_signin_spa_sudio';
export const OKTA_CONFIG_ID_NON_PROD = 'cmd_qa_bridge_signin_spa_sudio';
