// externals
import { Reducer } from 'redux';

// libraries
// import { initActionTypes } from '@makemydeal/dr-shared-store';

// actions
// import { Actions } from './actions';

// interfaces
import { IStudio } from '../types/IStudio';

export const reducer: Reducer<IStudio> = (state: IStudio = {}, action: any) => {
    const { _payload, type } = action;

    switch (type) {
        default: {
            return state;
        }
    }
};
