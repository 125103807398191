import { IConfigStudio, MiddlewareManager } from '@makemydeal/dr-activities-studio';
import { MiddlewareAPI } from 'redux';
import { payloadUtils } from '@makemydeal/dr-shared-ui-utils';
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { bootstrapActionTypes } from '@makemydeal/dr-shared-store';
import { configureStudio } from './config';

export let cachedStudioMiddlewareManager: MiddlewareManager | undefined;

// needed for testing
export const studioMiddlewareClearCache = () => (cachedStudioMiddlewareManager = undefined);
type StudioInitConfig = {
    services: {
        gateway: any;
    };
};

export const studioMiddleware = (store: MiddlewareAPI) => (next: any) => (action: AnyFSA) => {
    if (action.type === bootstrapActionTypes.BOOTSTRAP_SUCCESS) {
        const featureToggles = payloadUtils.getFeatureTogglesFromInitPayload(action.payload);
        const { services: { gateway } = {} } = payloadUtils.getConfigFromInitPayload<StudioInitConfig>(action.payload);

        // TODO: fill with necessary config data
        const studioConfig = {
            services: {
                gateway
            },
            featureToggles
        } as IConfigStudio;

        cachedStudioMiddlewareManager = configureStudio(studioConfig);
    }

    if (cachedStudioMiddlewareManager) {
        cachedStudioMiddlewareManager.middleware(store)(next)(action);
    } else {
        next(action);
    }
};
