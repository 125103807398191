import { BaseSelectors } from '@makemydeal/dr-activities-common';

import { IAdditionalSelectors } from '../types/IAdditionalSelectors';
import { IStudio } from '../types/IStudio';

export class Selectors extends BaseSelectors {
    constructor(public sliceName: string, public additionalSelectors: IAdditionalSelectors) {
        super(sliceName);

        // TODO: Arrow Functions or specify bind statements here
        // otherwise, these functions will lose this context
    }

    /**
     * Get the slice of state for this activity
     * @param state Entire State Tree
     */
    getState = (state: any): IStudio => {
        return super.getState(state);
    };

    // Add extra selectors here
}
